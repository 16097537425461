import React from "react";
import { render } from "react-dom";
import WaterWave from "react-water-wave";
import image from "../../img/bg4.jpg";
import { BiRegistered } from "react-icons/bi";
import { Link } from "react-scroll";
const WaterHero = () => (
  <section id="hero">
    <WaterWave id="ripple" imageUrl={image}>
      {(methods) => (
        <div className="fn_cs_hero_slider">
          <div className="bg_overlay" />
          <div className="left_part">
            <h3 className="fn__maintitle big" data-text="XENO LEARNING">
              XENO LEARNING
            </h3>
            <p>
              XENO Learning is an online platform dedicated to teaching coding
              skills to learners of all levels. Our expert instructors provide
              comprehensive and personalized instruction, equipping students
              with the tools they need to succeed in today's digital age.
              Whether you're a beginner looking to develop basic coding skills
              or an advanced learner pursuing a career in technology, Xeno
              coding Learning has the courses and resources to help you achieve
              your goals. Join our community today and start your journey
              towards mastering coding.
            </p>
            <Link
            to="section3"
            style={{cursor:'pointer'}}
            className="metaportal_fn_button">
              <span>Course Details</span>
            </Link>
          </div>
          <div className="slider_part">
            <div className="head-u">
              <h3 className="fn__maintitle2 big head-th  biyy" data-text="XENO">
                XENO
              </h3>

              <span className="span-op">
                <BiRegistered />
              </span>
            </div>
            {/* <Swiper {...Hero4Slider} className="swiper-container">
              <div className="swiper-wrapper">
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/1.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/2.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/3.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                      <a
                        className="popup-youtube metaportal_fn_videobutton"
                        href="https://www.youtube.com/embed/7e90gBu4pas"
                      >
                        <img src="/svg/play.svg" alt="" className="fn__svg" />
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/4.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/5.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/6.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/7.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/8.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/9.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className="swiper-slide">
                  <div className="item">
                    <div className="img_holder">
                      <div
                        className="abs_img"
                        data-bg-img="/img/drops/10.jpg"
                        style={{}}
                      />
                      <img src="/img/1x1.jpg" alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            </Swiper> */}
          </div>
        </div>
      )}
    </WaterWave>
  </section>
);

export default WaterHero;
