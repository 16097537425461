import React, { useState } from "react";
import styled from "styled-components";

import { AiOutlineClose, AiOutlineHome } from "react-icons/ai";
import { Link, animateScroll as scroll } from "react-scroll";
import { HiMenuAlt3 } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const StyledOffCanvas = styled.aside`
  position: fixed;
  top: 0;
  left: ${(props) => (props.isOpen ? 0 : "-100%")};
  width: 300px;
  height: 100%;
  background-color: #191023;
  z-index: 500;
  color: white;
  transition: left 0.5s ease-out;
`;

const StyledOffCanvasOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header id="header">
      {/* webnav */}
      <div className="nav-hh">
        <div className={colorChange ? "header colorChange-nav" : "header"}>
          <div className="header_in">
            <div className="trigger_logo">
              <div className="logo">
                <span
                  onClick={() => navigate("/")}
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <img
                    src={require("../img/logo.png")}
                    alt=""
                    style={{ width: "100px" }}
                  />
                </span>
              </div>
            </div>
            <div className="nav" style={{ opacity: 1 }}>
              <ul>
                <li>
                  <Link
                    activeClass="active"
                    to="section1"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <a className="creative_link">Home</a>
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="section2"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <span className="creative_link">About</span>
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="section3"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <span className="creative_link">Course Details</span>
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="section4"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <span className="creative_link">Blog</span>
                  </Link>
                </li>
                <li>
                  <Link
                    activeClass="active"
                    to="section5"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <a className="creative_link">Contact</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* mobile nav */}
      <div className="nav-mo">
        <div className="mobile-menu-nav header2">
          <button className="bb-none" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <AiOutlineClose className="open-tab-mob" size={30} />
            ) : (
              <HiMenuAlt3 size={30} className="open-tab-mob" />
            )}
          </button>
          <div>
            <div className="trigger_logo">
              <div className="logo">
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <img
                    src={require("../img/logo.png")}
                    alt=""
                    style={{ width: "70px" }}
                  />
                </Link>
              </div>
            </div>
            <StyledOffCanvas isOpen={isOpen}>
              <div className="head-mobile-menu">
                <ul className="ul-menu-mobile" style={{ listStyle: " none" }}>
                  <li>
                    <div className="mobile-k-m">
                      <Link to="/User">
                        <span
                          onClick={() => setIsOpen(false)}
                          className="link-k"
                        ></span>
                      </Link>
                    </div>
                  </li>

                  <ul style={{ listStyle: " none" }} className="mobile-tab-ul">
                    <li className="list-font-ss">
                      <Link
                        activeClass="active"
                        to="section1"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        <span
                          onClick={() => setIsOpen(false)}
                          className="creative_link"
                        >
                          Home
                        </span>
                      </Link>
                    </li>
                    <li className="list-font-ss">
                      <Link
                        activeClass="active"
                        to="section2"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        <span
                          onClick={() => setIsOpen(false)}
                          className="creative_link"
                        >
                          About
                        </span>
                      </Link>
                    </li>
                    <li className="list-font-ss">
                      <Link
                        activeClass="active"
                        to="section3"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        <span
                          onClick={() => setIsOpen(false)}
                          className="creative_link"
                        >
                          Course Details
                        </span>
                      </Link>
                    </li>
                    <li className="list-font-ss">
                      <Link
                        activeClass="active"
                        to="section4"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        <span
                          onClick={() => setIsOpen(false)}
                          className="creative_link"
                        >
                          Blog
                        </span>
                      </Link>
                    </li>
                    <li className="list-font-ss">
                      <Link
                        activeClass="active"
                        to="section5"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                      >
                        <span
                          onClick={() => setIsOpen(false)}
                          className="creative_link"
                        >
                          Contact
                        </span>
                      </Link>
                    </li>
                  </ul>
                </ul>
              </div>
            </StyledOffCanvas>
            <StyledOffCanvasOverlay
              isOpen={isOpen}
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
