import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./Components/Pages/Home/Home";
import '../src/Components/f2/css/plugins.css'
import '../src/Components/f2/css/style.css'
import './Components/Styles/globals.css'
import './Components/Styles/Home.module.css'
import Term from '../src/Components/Pages/PrivacyTerm/Terms';
import Navbar from "./Components/layout/Navbar";
import ScrollTop from "./Components/layout/ScrollTop";

import PrivacyPolicy from '../src/Components/Pages/PrivacyTerm/PrivacyPolicy'
import Footer from "./Components/layout/Footer";
import ScrollToTop from "./Components/Features/Scrollontop";

const App = () => {
  return (
<>

    <Router>
{/* <Navbar/> */}
<ScrollToTop/>
<ScrollTop/>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy/>} />
        <Route exact path="/TermsOfUse" element={<Term/>} />

      </Routes>
      <Footer/>
    </Router>
</>


  );
};

export default App;
