import { Link } from "react-router-dom";

const News = () => {
  return (
    <section id="news">
      <div className="container">
        <h3
          className="fn__maintitle big"
          data-text="Latest Blog"
          data-align="center"
        >
          Latest Blog
        </h3>
        {/* News Shotcode */}
        <div className="fn_cs_news">
          <div className="news_part">
            <div className="left_items">
              <div className="blog__item">
                <div className="counter">
                  <span className="cc">
                    <span>1</span>
                  </span>
                </div>
                <div className="title">
                  <h3>
                    <Link>
                      <a>
                        Welcome to XENO learning, your one-stop destination for
                        online coding classes. In this blog post, we'll be
                        discussing why online coding classes are the future of
                        education and how XENO learning is leading the way.
                      </a>
                    </Link>
                  </h3>
                </div>
                <div className="meta">
                  <p>
                    The world is rapidly changing, and technology is advancing
                    at a breakneck pace. To keep up with these changes, it's
                    essential to learn new skills and stay up-to-date with the
                    latest trends. Coding is one such skill that is becoming
                    increasingly important in today's world.
                  </p>
                </div>
                <div className="meta">
                  <p>
                    Traditionally, coding classes have been conducted in person,
                    with students attending classes at a physical location.
                    However, with the rise of the internet and the increasing
                    availability of high-speed internet connections, online
                    coding classes have become more popular than ever.
                  </p>
                </div>
                <div className="meta">
                  <p>
                    Online coding classes offer a number of advantages over
                    traditional in-person classes. For one, they are more
                    convenient, as students can attend classes from the comfort
                    of their own homes. This means that students don't have to
                    spend time and money commuting to a physical location, and
                    they can d classes at a time that is convenient for
                    them.
                  </p>
                </div>
                <div className="meta">
                  <p>
                    Online coding classes also offer more flexibility than
                    traditional in-person classes. With online classes, students
                    can learn at their own pace and can revisit topics as many
                    times as they need to. This means that students can focus on
                    areas where they need more help and can move quickly through
                    areas where they are already proficient.
                  </p>
                </div>
                <div className="meta">
                  <p>
                    At XENO learning, we offer a wide range of online coding
                    classes, from beginner-level courses to advanced courses in
                    specialized areas. Our courses are taught by experienced
                    instructors who are experts in their fields. We use a
                    variety of teaching methods to help our students learn,
                    including video lectures, interactive exercises, and
                    hands-on project
                  </p>
                </div>
                <div className="meta">
                  <p>
                    One of the unique features of XENO learning is our
                    personalized approach to teaching. We recognize that every
                    student is different, with their own learning style and
                    pace. That's why we offer personalized feedback and support
                    to our students, helping them to succeed in their coding
                    journey.
                  </p>
                </div>
                <div className="meta">
                  <p>
                    In addition to our coding classes, we also offer a number of
                    resources to help our students succeed. These include a
                    community forum where students can ask questions and get
                    help from their peers, as well as a library of resources and
                    tutorials.
                  </p>
                </div>
                <div className="meta">
                  <p>
                    At XENO learning, we believe that online coding classes are
                    the future of education. With their convenience,
                    flexibility, and personalized approach, online classes offer
                    a number of advantages over traditional in-person classes.
                    If you're looking to learn coding, we invite you to check
                    out our courses and see how XENO learning can help you
                    achieve your goals.
                  </p>
                </div>
                {/* <div className="image">
                  <Link >
                    <a>
                      <img src="/img/blog/1.jpg" alt="" />
                    </a>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="right_items">
              <div className="blog__item">
                <div className="counter">
                  <span className="cc">
                    <span>02</span>
                  </span>
                </div>
                {/* <div className="meta">
                  <p>August 09, 2022 / MetaVerse / 4 Comments</p>
                </div> */}
                <div className="title">
                  <h3>
                    <Link>
                      <a>Top Benefits of Learning to Code with XENO Learning
</a>
                    </Link>
                  </h3>
                </div>
                <div className="meta">
                  <p>
                  Coding is an in-demand skill that is becoming increasingly important in the job market. With XENO Learning's online coding classes, you can learn how to code and develop your skills from the comfort of your own home. Here are some of the top benefits of learning to code with XENO Learning:

                  </p>
                </div>
                <div className="meta">
                  <p>
                  Flexibility:
XENO Learning's online coding classes allow you to learn at your own pace and on your own schedule. Whether you are a full-time student or a working professional, you can fit coding classes into your busy schedule.


                  </p>
                </div>
                <div className="meta">
                  <p>
                  Expert Instructors:
XENO Learning has a team of experienced instructors who have a deep understanding of coding and can teach the course content effectively. You can learn from the best and get feedback on your progress.


                  </p>
                </div>
                <div className="meta">
                  <p>
                  Engaging Content:
XENO Learning's online coding classes are designed to be engaging, interactive, and easy to understand. The course content includes lectures, exercises, and assignments that align with the curriculum.



                  </p>
                </div>
                <div className="meta">
                  <p>
                  Accessible:
XENO Learning's online coding classes are accessible from anywhere in the world, as long as you have an internet connection. You don't have to worry about commuting or being restricted by location.



                  </p>
                </div>
                <div className="meta">
                  <p>
                  Valuable Certifications:
Upon completion of XENO Learning's coding courses, you will receive a certification that adds value to your resume and showcases your coding skills to potential employers.




                  </p>
                </div>
                <div className="meta">
                  <p>
                  In conclusion, learning to code with XENO Learning's online coding classes provides a range of benefits that can help you achieve your career goals. With flexible scheduling, expert instructors, engaging content, accessibility, and valuable certifications, you can take the first step towards a successful career in coding.




                  </p>
                </div>
              </div>
         
            </div>
          </div>
          {/* <div className="bottom_part">
            <div className="left_bot">
              <Link>
                <a className="metaportal_fn_button full">
                  <span>Read All Articles</span>
                </a>
              </Link>
            </div>
            <div className="right_bot">
              <p>
                We’re a team of creatives who are excited about unique ideas and
                create consectetur adipiscing elit. Etiam vitae purus a lectus
                semper.
              </p>
            </div>
          </div> */}
        </div>
        {/* !News Shotcode */}
      </div>
    </section>
  );
};
export default News;
